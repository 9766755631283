<style scoped>
.zht-component {
  position: absolute;
  box-sizing: border-box;
  transition-property: all;
  transition-duration: 1s;
}
.zht-component > *:nth-child(1) {
  width: 100%;
  height: 100%;
  transition-property: all;
  transition-duration: 1s;
}
</style>

<template>
  <div :style="style" ref="root">
    <div v-if="page" :style="pageStyle">
      <template v-for="component in page.controls">
        <div
          v-if="component.type != 'zht-component' || isPage"
          :key="component.uuid"
          class="zht-component"
          :style="{
            width: (component.size.width || 1) + 'px',
            height: (component.size.height || 1) + 'px',
            left: (component.location.x || 0) + 'px',
            top: (component.location.y || 0) + 'px',
          }"
        >
          <component
            :is="component.type"
            :control="component"
            :pageId="pageId"
            :isView="isView"
            @propChange="input"
          ></component>
        </div>
      </template>
    </div>
    <v-card
      v-else-if="!loading"
      color="transparent"
      class="fill-height d-flex flex-column justify-center align-center"
    >
      <h1 class="white--text">无法展示此页面</h1>
      <h3 class="white--text">页面已删除或页面内容不匹配</h3>
    </v-card>
  </div>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
import base64 from "../utils/base64";
import ZHTUI from "../zht-ui";
import RES from "../utils/res";
import axios from "axios";

export default {
  props: {
    control: Object,
    pageId: String,
    isPage: Boolean,
    isView: Boolean,
    fillMode: Number,
    rootWidth: Number,
    rootHeight: Number,
  },
  data() {
    return {
      pageBgKey: "",
      pageBgUrl: "",
      page: null,
      loading: true,
      loadPageId: "",
      screenNotice: false,

      /** scan change and update */
      updateTimer: null,
      /** init data id list */
      source: { data: [], status: [], history: [] },
      /** cache data and mark binding changed. keys:
       * data-uuid
       * status-uuid
       * history-uuid
       * statistics
       * api-uuid
       */
      cache: {
        // "keys": { value: '/', propId: [] },
      },
      /** binding info */
      binding: {
        // propId: {
        //   componentId,
        //   prop1,
        //   prop2,
        //   JScript,
        //   cacheIds: [],
        //   change: false,
        // },
      },
      /** api */
      apis: {
        // bindingId: {
        //   param,
        //   interval,
        //   current,
        //   result,
        // },
      },
      lastTime: 0,
      updateTime: 0,
    };
  },
  mounted() {
    this.change();
    if (this.isView) client.$on("prop-set", this.propertySet);
    if (this.isView) client.$on("spot-ctrl", this.spotControl);
    if (this.isView) client.$on("brocast", this.brocast);
    if (this.isView) client.$on("chnState", this.chnState);
    if (this.isView) this.updateTimer = setInterval(this.updateTick, 1000);
  },
  beforeDestroy() {
    if (this.isView) client.$off("prop-set", this.propertySet);
    if (this.isView) client.$off("spot-ctrl", this.spotControl);
    if (this.isView) client.$off("brocast", this.brocast);
    if (this.isView) client.$off("chnState", this.chnState);
    if (this.updateTimer) clearInterval(this.updateTimer);
  },
  watch: {
    "control.config": {
      handler() {
        this.change();
      },
      deep: true,
    },
    fillMode: {
      handler() {
        this.$refs.root.scrollTop = 0;
      },
    },
  },
  computed: {
    style() {
      let result = JSON.parse(JSON.stringify(this.control.style));
      if (this.page) {
        result.overflow = "hidden";
        if (this.isPage) {
          switch (this.fillMode) {
            case 0:
              result.overflow = "auto";
              break;
            case 1:
              result["overflow-x"] = "hidden";
              result["overflow-y"] = "auto";
              break;
            case 2:
              result.overflow = "hidden";
              break;
          }
          result.width = "100%";
          result.height = "100%";
        }
      } else {
        result.width = "100%";
        result.height = "100%";
        result["background-color"] = "rgba(127,127,127,0.2)";
      }
      return result;
    },
    pageStyle() {
      let result = JSON.parse(JSON.stringify(this.page.style));
      result.width = parseInt(this.page.size.width) + "px";
      result.height = parseInt(this.page.size.height) + "px";
      result.overflow = "hidden";
      result.position = "relative";
      if (this.isPage) {
        let scaleX = 1,
          scaleY = 1;
        switch (this.fillMode) {
          case 0:
            result.margin = "0 auto";
            break;
          case 1:
            result["transform-origin"] = "left top";
            if (this.page.size.width) {
              scaleX = scaleY = this.rootWidth / this.page.size.width;
            }
            result["transform"] = `scale(${scaleX},${scaleY})`;
            break;
          case 2:
            result["transform-origin"] = "left top";
            if (this.page.size.width) {
              scaleX = this.rootWidth / this.page.size.width;
            }
            if (this.page.size.height) {
              scaleY = this.rootHeight / this.page.size.height;
            }
            result["transform"] = `scale(${scaleX},${scaleY})`;
            break;
        }
      }
      result["background-position"] = "center";
      result["background-size"] = "100% 100%";
      if (result["background-image"]) {
        let imgValue = result["background-image"];
        if (imgValue.indexOf("url") != 0 && imgValue.length == 36) {
          if (this.pageBgKey != imgValue || this.pageBgUrl == "") {
            this.getResource(imgValue);
          }
          result["background-image"] = `url('${this.pageBgUrl}')`;
        }
      }
      return result;
    },
  },
  methods: {
    chnState(chnState) {
      if (chnState == proto.ChnState.ready) {
        this.initData(true);
      }
    },
    propertySet(action) {
      let uuid = action.uuid;
      let props = action.prop.split(".");
      let value = action.value;
      if (props.length != 2) return;
      if (this.page && this.page.controls) {
        for (let i in this.page.controls) {
          let ctrl = this.page.controls[i];
          if (ctrl.uuid == uuid) {
            try {
              ctrl[props[0]][props[1]] = value;
            } catch (e) {
              console.log(e);
            }
            break;
          }
        }
      }
    },
    async spotControl(action) {
      if (!action.spot.uuid) return;
      if (action.srcType == "const") return;
      if (!action.uuid) return;
      if (!action.prop) return;
      let value;
      let uuid = action.uuid;
      let props = action.prop.split(".");
      if (this.page && this.page.controls) {
        for (let i in this.page.controls) {
          let ctrl = this.page.controls[i];
          if (ctrl.uuid == uuid) {
            try {
              value = ctrl[props[0]][props[1]];
            } catch (e) {
              console.log(e);
            }
            break;
          }
        }
      }
      if (value === undefined) return;
      let cid = this.control.uuid;
      client.$emit(
        "startBusy",
        `ZhtComponent.spotControl.${cid}`,
        "正在控制测点,请稍候..."
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.realTimeDataMessage, {
          mcd: {
            operate: proto.OperateMode.updateOpt,
            range: action.spot.uuid,
          },
          rtdata: [
            {
              uuid: action.spot.uuid,
              sv: {
                valueData: base64.encode(value),
              },
            },
          ],
        });
        client.$emit("toast", "控制成功", "success");
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", `ZhtComponent.spotControl.${cid}`);
    },
    cacheChanged(propIds) {
      for (let i in propIds) {
        this.binding[propIds[i]].change = true;
      }
    },
    async getHistory(ignoreHistory) {
      let ids = this.source.history;
      if (!ignoreHistory) {
        for (let i in ids) {
          try {
            let res = await client.send(proto.MESSAGE_TYPE.spotMessage, {
              mcd: {
                operate: proto.OperateMode.queryOpt,
                range: ids[i],
              },
              act: 0,
            });
            if (!res.spots || res.spots.length != 1) continue;
            let devId = res.spots[0].pid;
            res = await client.send(proto.MESSAGE_TYPE.deviceMessage, {
              mcd: {
                operate: proto.OperateMode.queryOpt,
                range: devId,
              },
              act: 0,
            });
            if (
              !res.devices ||
              res.devices.length != 1 ||
              !res.devices[0].organization
            )
              continue;
            let orgid = res.devices[0].organization.id;
            res = await client.send(proto.MESSAGE_TYPE.histDataMessage, {
              mcd: {
                operate: proto.OperateMode.retrieveOpt,
                range: "0",
                orgid,
              },
              histdataClause: {
                timeContion: 5,
                begintime: new Date(Date.now() + 8 * 3600)
                  .toISOString()
                  .substring(0, 10),
                uuid: ids[i],
                adpType: 3,
              },
              rpp: 300,
              pageindex: 0,
            });
            if (res.hds) {
              res.hds.sort((a, b) => {
                return a.datetime.localeCompare(b.datetime);
              });
              for (let i in res.hds) {
                let data = res.hds[i];
                let value = "/";
                if (data.sv) value = base64.decode(data.sv);
                if (data.st == 1) value = parseFloat(value);
                if (data.st == 1 && !value) value = 0;
                if (this.cache[`history-${data.sid}`]) {
                  this.cache[`history-${data.sid}`].value =
                    this.cache[`history-${data.sid}`].value || [];
                  this.cache[`history-${data.sid}`].value.push({
                    time: data.datetime,
                    value,
                  });
                  this.cacheChanged(this.cache[`history-${data.sid}`].propId);
                }
              }
            }
          } catch (error) {
            client.$emit("toast", error);
          }
        }
      }
      try {
        let rtdata = [];
        for (let i in ids) {
          rtdata.push({ uuid: ids[i], valueType: 1 });
        }
        if (rtdata.length) {
          let res = await client.send(proto.MESSAGE_TYPE.realTimeDataMessage, {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
            },
            filterType: 1,
            rtdata,
          });
          if (res.rtdata) {
            for (let i in res.rtdata) {
              let data = res.rtdata[i];
              let value = "/";
              if (data.sv && data.sv.valueData)
                value = base64.decode(data.sv.valueData);
              if (/^[0-9]+\.?[0-9]*$/.test(value)) value = parseFloat(value);
              if (this.cache[`history-${data.uuid}`]) {
                this.cache[`history-${data.uuid}`].value =
                  this.cache[`history-${data.uuid}`].value || [];
                this.cache[`history-${data.uuid}`].value.push({
                  time: new Date(Date.now() + 8 * 3600000)
                    .toISOString()
                    .substring(0, 19)
                    .replace("T", " "),
                  value,
                });
                let len = this.cache[`history-${data.uuid}`].value.length;
                if (len > 300)
                  this.cache[`history-${data.uuid}`].value.splice(0, len - 300);
                this.cacheChanged(this.cache[`history-${data.uuid}`].propId);
              }
            }
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
    },
    async getData() {
      try {
        let rtdata = [];
        let ids = this.source.data;
        for (let i in ids) {
          rtdata.push({ uuid: ids[i] });
        }
        if (rtdata.length) {
          let res = await client.send(proto.MESSAGE_TYPE.realTimeDataMessage, {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
            },
            filterType: 1,
            rtdata,
          });
          if (res.rtdata) {
            for (let i in res.rtdata) {
              let data = res.rtdata[i];
              let value = "/";
              if (data.sv && data.sv.valueData)
                value = base64.decode(data.sv.valueData);
              if (/^[0-9]+\.?[0-9]*$/.test(value)) value = parseFloat(value);
              if (this.cache[`data-${data.uuid}`]) {
                this.cache[`data-${data.uuid}`].value = value;
                this.cacheChanged(this.cache[`data-${data.uuid}`].propId);
              }
            }
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
    },
    async getStatus() {
      try {
        let so = [];
        let ids = this.source.status;
        for (let i in ids) {
          so.push({ uuid: ids[i] });
        }
        if (so.length) {
          let res = await client.send(proto.MESSAGE_TYPE.statusMessage, {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
              range: "-1",
            },
            so,
          });
          if (res.so) {
            for (let i in res.so) {
              let status = res.so[i];
              if (this.cache[`status-${status.uuid}`]) {
                this.cache[`status-${status.uuid}`].value = status.state;
                this.cacheChanged(this.cache[`status-${status.uuid}`].propId);
              }
              if (this.cache[`alarm-${status.uuid}`]) {
                this.cache[`alarm-${status.uuid}`].value = status.stateAlarm;
                this.cacheChanged(this.cache[`alarm-${status.uuid}`].propId);
              }
            }
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
    },
    async initBinding() {
      this.apis = {}; // stop exec
      this.binding = {}; // stop update
      this.updateTime = 0; // reset first time
      let dataDict = {};
      let statusDict = {};
      let historyDict = {};
      let api = {};
      let cache = {};
      let binding = {};
      let bindings = this.page.bindings;
      for (let i in bindings) {
        let bind = bindings[i];
        let propId = bind.id;
        let componentId = bind.uuid;
        let props = bind.prop;
        if (!propId || !componentId || !props) continue;
        props = props.split(".");
        if (props.length != 2) continue;
        let prop1 = props[0];
        let prop2 = props[1];
        if (!prop1 || !prop2) continue;
        let JScript = bind.JScript;
        binding[propId] = {
          componentId,
          prop1,
          prop2,
          JScript,
          cacheIds: {},
          change: false,
        };
        if (bind.srcType == "api") {
          let model = JSON.parse(JSON.stringify(bind));
          let body = model.param;
          try {
            model.param = await this.transformData(body, {});
          } catch (e) {
            console.log(e);
            model.param = {};
          }
          model.interval = parseInt(model.interval);
          if (!model.interval) model.interval = 10;
          if (model.interval < 5) model.interval = 5;
          model.interval = model.interval * 1000;
          model.current = model.interval;
          api[propId] = model;
          cache[`api-${propId}`] = { value: "/", propId: {} };
          cache[`api-${propId}`].propId[propId] = true;
          binding[propId].cacheIds[`api-${propId}`] = true;
          binding[propId].change = true;
        } else if (bind.srcType == "statistics") {
          if (!cache[`statistics`]) {
            cache[`statistics`] = { value: client.statistics, propId: {} };
          }
          cache[`statistics`].propId[propId] = true;
          binding[propId].cacheIds[`statistics`] = true;
          binding[propId].change = true;
        } else {
          for (let j in bind.src) {
            let src = bind.src[j];
            switch (bind.valueType) {
              case "alarm":
                statusDict[src.id] = true;
                if (!cache[`alarm-${src.id}`]) {
                  cache[`alarm-${src.id}`] = { value: "/", propId: {} };
                }
                cache[`alarm-${src.id}`].propId[propId] = true;
                binding[propId].cacheIds[`alarm-${src.id}`] = true;
                break;
              case "status":
                statusDict[src.id] = true;
                if (!cache[`status-${src.id}`]) {
                  cache[`status-${src.id}`] = { value: "/", propId: {} };
                }
                cache[`status-${src.id}`].propId[propId] = true;
                binding[propId].cacheIds[`status-${src.id}`] = true;
                break;
              case "data":
                dataDict[src.id] = true;
                if (!cache[`data-${src.id}`]) {
                  cache[`data-${src.id}`] = { value: "/", propId: {} };
                }
                cache[`data-${src.id}`].propId[propId] = true;
                binding[propId].cacheIds[`data-${src.id}`] = true;
                break;
              case "history":
                historyDict[src.id] = true;
                if (!cache[`history-${src.id}`]) {
                  cache[`history-${src.id}`] = { value: [], propId: {} };
                }
                cache[`history-${src.id}`].propId[propId] = true;
                binding[propId].cacheIds[`history-${src.id}`] = true;
                break;
            }
          }
        }
      }
      let keys = Object.keys(cache);
      for (let i in keys) {
        cache[keys[i]].propId = Object.keys(cache[keys[i]].propId);
      }
      keys = Object.keys(binding);
      for (let i in keys) {
        binding[keys[i]].cacheIds = Object.keys(binding[keys[i]].cacheIds);
      }
      this.source.data = Object.keys(dataDict);
      this.source.status = Object.keys(statusDict);
      this.source.history = Object.keys(historyDict);
      this.cache = cache;
      this.binding = binding;
      this.apis = api;
      this.initData();
    },
    async initData(ignoreHistory) {
      await this.getHistory(ignoreHistory);
      await this.getData();
      await this.getStatus();
      this.updateTick();
    },
    async apiExec(key, api, data) {
      try {
        if (api == "客户端脚本") {
          if (!this.cache[`api-${key}`]) return;
          this.cacheChanged(this.cache[`api-${key}`].propId);
        } else {
          let host = client.getAddress();
          let res = await axios.post(`${host}/api/exec`, {
            api,
            data,
          });
          if (res.data) {
            if (res.data.result) {
              if (!this.cache[`api-${key}`]) return;
              this.cache[`api-${key}`].value = res.data.result;
              this.cacheChanged(this.cache[`api-${key}`].propId);
            } else if (res.data.error) {
              console.log(res.data.error);
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async transformData(script, values) {
      script = script.replace(
        /setInterval\(/g,
        "console.warn('setInterval不能出现在绑定脚本中',("
      );
      let fn = new window.AsyncFunction("values", "client", script);
      return await fn(values, client);
    },
    async updateTick() {
      let now = Date.now();
      let cost = 0;
      if (this.lastTime != 0) {
        cost = now - this.lastTime;
      }
      this.lastTime = now;
      if (!this.page) return;
      // do apis
      let apiKeys = Object.keys(this.apis);
      if (apiKeys.length) {
        for (let i in apiKeys) {
          let key = apiKeys[i];
          let model = this.apis[key];
          if (model.current >= model.interval) {
            model.current = cost;
            this.apiExec(key, model.valueType, model.param);
          } else {
            model.current += cost;
          }
        }
      }
      // update binding
      let bindingKeys = Object.keys(this.binding);
      if (bindingKeys.length && now - this.updateTime >= 3000) {
        this.updateTime = now;
        for (let i in bindingKeys) {
          let propId = bindingKeys[i];
          let bind = this.binding[propId];
          if (bind.change) {
            let element;
            for (let j in this.page.controls) {
              if (this.page.controls[j].uuid == bind.componentId) {
                element = this.page.controls[j];
                break;
              }
            }
            if (!element) continue;
            let values = [];
            for (let j in bind.cacheIds) {
              let cacheKey = bind.cacheIds[j];
              values.push(this.cache[cacheKey].value);
            }
            try {
              let newData = await this.transformData(bind.JScript, values);
              if (element[bind.prop1][bind.prop2] != newData) {
                element[bind.prop1][bind.prop2] = newData;
              }
            } catch (e) {
              console.log(e);
            }
            bind.change = false;
          }
        }
      }
    },
    async change() {
      if (this.isPage) await this.clearFilter();
      if (this.control.config.pageId) {
        this.getPage(this.control.config.pageId);
      } else {
        this.page = null;
        this.loadPageId = "";
        this.pageBgKey = "";
        this.pageBgUrl = "";
      }
    },
    async getPage(id) {
      if (this.loadPageId == id) return;
      this.loadPageId = id;
      let result = null;
      let cid = this.control.uuid;
      client.$emit(
        "startBusy",
        `ZhtComponent.getPage.${cid}`,
        "正在加载页面,请稍候..."
      );
      this.loading = true;
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.pageMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "-1",
          },
          pages: [{ uuid: id }],
        });
        if (res.pages && res.pages.length) {
          let page = res.pages[0];
          let thumbnail = page.thumbnail;
          if (thumbnail) {
            thumbnail = base64.decode(thumbnail);
            if (thumbnail.substr(0, 7) == "ivisual") {
              let uuid = page.uuid;
              let name = page.name;
              let width = page.geometry.width;
              let height = page.geometry.height;
              let backgroundColor = "";
              let backgroundImage = "";
              if (page.bkGround.bkType == 1) {
                backgroundColor = page.bkGround.content;
              } else if (page.bkGround.bkType == 2) {
                backgroundImage = page.bkGround.content;
              }
              let model = JSON.parse(thumbnail.substr(7));
              let controls = model.controls || [];
              let bindings = model.bindings || [];
              for (let i = 0; i < controls.length; i++) {
                let control = controls[i];
                ZHTUI.fix(control.type, control);
              }
              result = {
                uuid,
                type: "zht-page",
                name,
                size: { width, height },
                style: {
                  "background-color": backgroundColor,
                  "background-image": backgroundImage,
                },
                controls,
                bindings,
              };
            }
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      if (result) {
        this.page = result;
        if (this.isView) await this.initBinding();
        if (this.isPage) {
          let isDevHorizon = this.rootWidth > this.rootHeight;
          let isPageHorizon = this.page.size.width > this.page.size.height;
          if (isDevHorizon != isPageHorizon && !this.screenNotice) {
            this.screenNotice = true;
            client.$emit(
              "toast",
              `建议使用${isDevHorizon ? "竖屏" : "横屏"}方式查看`,
              "info"
            );
          }
        }
      } else {
        this.page = null;
        this.loadPageId = "";
        this.pageBgKey = "";
        this.pageBgUrl = "";
      }
      this.loading = false;
      client.$emit("endBusy", `ZhtComponent.getPage.${cid}`);
    },
    async clearFilter() {
      try {
        await client.send(proto.MESSAGE_TYPE.realTimeDataMessage, {
          mcd: {
            operate: proto.OperateMode.createOpt,
          },
          rtdata: [],
        });
      } catch (error) {
        client.$emit("toast", error);
      }
    },
    async getResource(id) {
      this.pageBgKey = id;
      this.pageBgUrl = RES.loading;
      await proto.sleep(10);
      this.pageBgUrl = await RES.getResource(id);
    },
    brocast(type, msg) {
      switch (type) {
        case proto.MESSAGE_TYPE.realTimeDataMessage:
          if (msg.rtdata) {
            for (let i in msg.rtdata) {
              let data = msg.rtdata[i];
              let value = "/";
              if (data.sv && data.sv.valueData)
                value = base64.decode(data.sv.valueData);
              if (/^[0-9]+\.?[0-9]*$/.test(value)) value = parseFloat(value);
              if (this.cache[`data-${data.uuid}`]) {
                this.cache[`data-${data.uuid}`].value = value;
                this.cacheChanged(this.cache[`data-${data.uuid}`].propId);
              }
              if (this.cache[`history-${data.uuid}`]) {
                this.cache[`history-${data.uuid}`].value.push({
                  time: new Date(Date.now() + 8 * 3600000)
                    .toISOString()
                    .substr(0, 19)
                    .replace("T", " "),
                  value,
                });
                let len = this.cache[`history-${data.uuid}`].value.length;
                if (len > 300)
                  this.cache[`history-${data.uuid}`].value.splice(0, len - 300);
                this.cacheChanged(this.cache[`history-${data.uuid}`].propId);
              }
            }
          }
          break;
        case proto.MESSAGE_TYPE.realTimeEventMessage:
          if (msg.rtevent) {
            for (let i in msg.rtevent) {
              let event = msg.rtevent[i];
              if (this.cache[`alarm-${event.uuid}`]) {
                this.cache[`alarm-${event.uuid}`].value = event.stateAlarm;
                this.cacheChanged(this.cache[`alarm-${event.uuid}`].propId);
              }
            }
          }
          break;
        case proto.MESSAGE_TYPE.statusMessage:
          if (msg.so) {
            for (let i in msg.so) {
              let status = msg.so[i];
              if (this.cache[`alarm-${status.uuid}`]) {
                this.cache[`alarm-${status.uuid}`].value = status.stateAlarm;
                this.cacheChanged(this.cache[`alarm-${status.uuid}`].propId);
              }
              if (this.cache[`status-${status.uuid}`]) {
                this.cache[`status-${status.uuid}`].value = status.state;
                this.cacheChanged(this.cache[`status-${status.uuid}`].propId);
              }
            }
          }
          break;
        case proto.MESSAGE_TYPE.realTimeStatisticsMessage:
          setTimeout(() => {
            if (this.cache[`statistics`]) {
              this.cache[`statistics`].value = client.statistics;
              this.cacheChanged(this.cache[`statistics`].propId);
            }
          }, 500);
          break;
      }
    },
    input(id, prop, value) {
      if (this.isView && this.page) {
        for (let i in this.page.controls) {
          let ctrl = this.page.controls[i];
          if (ctrl.uuid == id) {
            try {
              let props = prop.split(".");
              ctrl[props[0]][props[1]] = value;
            } catch (e) {
              console.log(e);
            }
            break;
          }
        }
      }
    },
  },
};
</script>
